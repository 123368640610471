import KnAnnouncementCard from '../../components/KnAnnouncementCard/KnAnnouncementCard.vue';
import KnCardGraph from '../../components/KnCardGraph/KnCardGraph.vue';
import { mapGetters, mapMutations, mapState } from 'vuex';
import { getItem, postItem } from '../../../../api/api-methods';
import { numberFormatMX } from '../../../shared/helpers/dataUtils';
import {
  getFirstDayOfMonth,
  getLastDayOfMonth,
  formatStringDateToLocaleDate,
} from '../../../shared/helpers/dateUtils';
import { mapActions } from 'vuex/dist/vuex.common.js';
// import { paginationMixin } from '../../../shared/mixins/paginationMixin';
export default {
  name: 'TutorDashboardIndex',
  components: { KnAnnouncementCard, KnCardGraph },
  mixins: [],
  props: {},
  data() {
    return {
      studentsPerTutor: 0,
      groups: [],
      students: [],
      announcements: [],
      studentIds: [],
      groupIds: [],
      announcementLoading: false,
      currentDebt: 0,
      overdueDebt: 0,
      currentDebtNumber: 0,
      overdueDebtNumber: 0,
      dateRange: {
        from: '',
        to: '',
      },
    };
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    ...mapGetters(['nameUserLoggedIn', 'isTutor']),
    getTutorId() {
      return this.isTutor ? this.userData.perfiles[2].tutor[0].id : null;
    },
  },
  async created() {
    this.setIsLogin(false);
    this.announcementLoading = true;
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    this.dateRange.from = getFirstDayOfMonth(currentYear, currentMonth);
    this.dateRange.to = formatStringDateToLocaleDate({ date: today }); // getLastDayOfMonth(currentYear, currentMonth);
    const lastDayPrevMonth = getLastDayOfMonth(currentYear, currentMonth - 1);
    await this.getStudentsPerTutor();
    await this.getTotalOverdueDebt(lastDayPrevMonth);
    await this.getTotalCurrentDebt();
    await this.getStudents();
    await this.getGroups();
    await this.getAnnouncements();
    this.setIfTutorHasDebts({
      overdueMount: this.overdueDebtNumber,
      currentMount: this.currentDebtNumber,
    });
    this.announcementLoading = false;
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    ...mapActions(['setIfTutorHasDebts']),
    async getStudentsPerTutor() {
      try {
        const response = await postItem(
          '/app-personas/helpers/numero-total-hijos-tutorados',
          {
            id_tutor: this.getTutorId,
          }
        );
        if (response && response.numero_hijos_tutorados) {
          this.studentsPerTutor = response.numero_hijos_tutorados;
        }
      } catch (error) {
        console.error('Error al obtener alumnos por tutor. ', error);
      }
    },
    async getTotalCurrentDebt() {
      try {
        const response = await postItem(
          '/app-administracion/helpers/get-adeudo-actual-tutor',
          {
            id_tutor: this.getTutorId,
          }
        );
        if (response) {
          this.currentDebt = numberFormatMX.format(response);
          this.currentDebtNumber = response;
        }
      } catch (error) {
        console.error('Error al obtener alumnos por tutor. ', error);
      }
    },
    async getTotalOverdueDebt(lastDayMonth) {
      try {
        const response = await postItem(
          '/app-administracion/helpers/get-adeudos-vencidos-tutor',
          {
            id_tutor: this.getTutorId,
            fecha: lastDayMonth,
          }
        );
        if (response) {
          this.overdueDebt = numberFormatMX.format(response);
          this.overdueDebtNumber = response;
        }
      } catch (error) {
        console.error('Error al obtener alumnos por tutor. ', error);
      }
    },
    async fetchStudents({
      institutionId = null,
      tutorId = null,
      systemStatus = null,
      limit = 50,
      offset = 0,
    }) {
      try {
        const institutionFilter = institutionId
          ? `institucion_educativa=${institutionId}`
          : '';
        const tutorFilter = tutorId ? `&tutores=${tutorId}` : '';
        const systemStatusFilter =
          systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';
        const limitFilter = limit !== null ? `&limit=${limit}` : '';
        const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
        const studentsResponse = await getItem(
          '/app-personas/filters/alumno?'.concat(
            institutionFilter,
            tutorFilter,
            systemStatusFilter,
            limitFilter,
            offsetFilter
          )
        );
        if (studentsResponse) {
          return studentsResponse.results;
          // this.students = studentsResponse.results;
          // this.studentIds = this.students.map((student) => student.id);
          //console.log('Ids de estudiantes', this.studentIds);
        }
        return [];
      } catch (error) {
        console.error('Error al intentar obtener alumnos. ', error);
        return [];
      }
    },
    async fetchGroups({
      institutionId = null,
      studentId = null,
      groupName = null,
      systemStatus = null,
      limit = 50,
      offset = 0,
    }) {
      try {
        this.group = [];
        this.items = [];
        const institutionFilter = institutionId
          ? `institucion_educativa=${institutionId}`
          : '';
        const studentFilter = studentId ? `&alumnos=${studentId}` : '';
        const systemStatusFilter =
          systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';
        const searchFilter =
          groupName !== null ? `&nombre_grupo=${groupName}` : '';
        const limitFilter = limit !== null ? `&limit=${limit}` : '';
        const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
        const groupsResponse = await getItem(
          '/app-personas/filters/grupo?'.concat(
            institutionFilter,
            studentFilter,
            systemStatusFilter,
            searchFilter,
            limitFilter,
            offsetFilter
          )
        );
        if (groupsResponse) {
          // this.groups = groupsResponse.results;
          // this.groupIds = this.groups.map((group) => group.id);
          // console.log('Ids de grupos', this.groupIds);
          return groupsResponse.results;
        }
        return [];
      } catch (error) {
        console.error('Error al obtener grupos. ', error);
        return [];
      }
    },
    async fetchAnnouncements({
      institutionId = null,
      groupId = null,
      isGeneral = null,
      // announcementName = null,
      startDate = null,
      endDate = null,
      systemStatus = null,
      limit = 50,
      offset = 0,
    }) {
      try {
        const institutionFilter = institutionId
          ? `institucion_educativa=${institutionId}`
          : '';
        const groupFilter = groupId !== null ? `&grupos=${groupId}` : '';
        const isGeneralFilter =
          isGeneral !== null ? `&es_general=${isGeneral}` : '';
        const rangeFilter =
          startDate && endDate
            ? `&fecha_inicio=${startDate}&fecha_fin=${endDate}`
            : '';
        const systemStatusFilter =
          systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';
        const limitFilter = limit !== null ? `&limit=${limit}` : '';
        const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
        const announcementResponse = await getItem(
          '/app-personas/filters/anuncios?'.concat(
            institutionFilter,
            groupFilter,
            isGeneralFilter,
            rangeFilter,
            systemStatusFilter,
            limitFilter,
            offsetFilter
          )
        );
        if (announcementResponse) {
          return announcementResponse.results;
        }
        return [];
      } catch (error) {
        console.error('Error al intentar obtener anuncios. ', error);
        return [];
      }
    },
    async getStudents() {
      this.students = await this.fetchStudents({
        institutionId: this.institutionId,
        tutorId: this.getTutorId,
        systemStatus: true,
      });
      this.studentIds = this.students.map((s) => s.id);
    },
    async getGroups() {
      const groupSet = new Set();
      let studentsGroups = [];
      if (this.studentIds.length) {
        for (const sId of this.studentIds) {
          studentsGroups = await this.fetchGroups({
            institutionId: this.institutionId,
            studentId: sId,
            systemStatus: true,
          });
          studentsGroups.map((sg) => groupSet.add(sg.id));
        }
        this.groupIds = [...groupSet];
      }
    },
    async getAnnouncements() {
      const announcementsSet = new Set();
      let generalAnnouncements = [];
      let groupAnnouncements = await this.fetchAnnouncements({
        institutionId: this.institutionId,
        groupId: this.groupIds.length ? this.groupIds.join(',') : null,
        systemStatus: true,
        startDate: this.dateRange.from,
        endDate: this.dateRange.to,
      });
      groupAnnouncements.map((gA) => announcementsSet.add(gA.id));
      generalAnnouncements = await this.fetchAnnouncements({
        institutionId: this.institutionId,
        isGeneral: true,
        systemStatus: true,
        startDate: this.dateRange.from,
        endDate: this.dateRange.to,
      });
      generalAnnouncements.map((gA) => announcementsSet.add(gA.id));
      const allAnnouncements = [...groupAnnouncements, ...generalAnnouncements];
      // console.log('announcementSet', announcementsSet);
      const announcementsIds = [...announcementsSet];
      announcementsIds.forEach((aId) => {
        const findedAnnouncement = allAnnouncements.find((a) => a.id === aId);
        if (findedAnnouncement) {
          this.announcements.push(findedAnnouncement);
        }
      });
      // console.log('Anuncios obtenidos no repetidos', this.announcements);
    },
  },
};

import { downloadFileFromUrl } from '../../../shared/helpers/dataUtils';
const emptyAnnouncement = {
  autor: null,
  contenido: '',
  es_general: false,
  estatus_sistema: true,
  fecha_fin: null,
  fecha_inicio: null,
  grupos: [],
  id: null,
  imagenes_anuncios: [],
  institucion_educativa: null,
  nombre_anuncio: '',
};
export default {
  name: 'KnAnnouncementCard',
  components: {},
  props: {
    announcement: {
      type: Object,
      default: () => emptyAnnouncement,
    },
  },
  data() {
    return {};
  },
  computed: {
    audienceText() {
      return this.announcement.es_general
        ? 'Anuncio general'
        : this.announcement.grupos.length
        ? `Grupos: ${this.announcement.grupos
            .map((g) => g.nombre_grupo)
            .join(', ')}`
        : 'Sin asignar';
    },
    authorName() {
      return this.announcement.autor &&
        this.announcement.autor.first_name &&
        this.announcement.autor.last_name
        ? `${this.announcement.autor.first_name} ${this.announcement.autor.last_name}`
        : '';
    },
  },
  created() {},
  methods: {
    downloadFileFromUrl: downloadFileFromUrl,
  },
};
